const HomepageJsonLd = {
  '@type': 'WebSite',
  '@id': 'https://www.tabletmag.com/about',
  url: 'https://www.tabletmag.com/',
  sourceOrganization: {
    '@type': 'NewsMediaOrganization',
    '@id': 'https://www.tabletmag.com/',
    url: 'https://www.tabletmag.com/',
    description:
      'Tablet features news, essays, podcasts, and opinion, covering arts, pop culture, technology, holidays, sports, and more.',
    foundingDate: '2009-06-01',
    masthead: 'https://www.tabletmag.com/about',
    name: 'Tablet',
    logo: {
      '@type': 'ImageObject',
      height: '258',
      width: '258',
      url: 'https://cdn.sanity.io/images/z2aip6ei/production/77982df193a4def197764e8423e230afbe3fe20f-2386x364.jpg?h=500&fit=max',
    },
  },
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://www.tabletmag.com/search?search={query}',
    query: 'required',
  },
};

export default HomepageJsonLd;
