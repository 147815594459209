import React, { Component, RefObject, createRef } from 'react';
import { Link } from '../components/Link';
import { format } from 'date-fns';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import cx from 'classnames';
import { TabletLogo } from '../components/icons';
import TopBar from '../components/TopBar';
import Nav from '../components/Nav';
import { RouteMap } from '../constants/RouteMap';
import { onWindowResize } from '../utils/onWindowResize';
import { getHebrewDateLocal } from '../utils/hebrewDate';
import { HolidayBanner, Image } from '../sharedTypes';
import { Img } from './base';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

interface Props {
  isSectionNav?: boolean;
  holidayBanner?: HolidayBanner;
  featuredImage?: Image;
  featuredLink?: string;
  showTagline?: boolean;
  taglineText?: string;
}

interface State {
  scrollPosition: number;
  topBarPosition: number;
}

class HomeNav extends Component<Props, State> {
  topBarRef: RefObject<HTMLDivElement> = createRef();

  state: State = {
    scrollPosition: 0,
    topBarPosition: 0,
  };

  removeResizeListener = () => {};

  getScrollPosition = () => {
    const scrollTop =
      get(window, 'pageYOffset', 0) ||
      get(document, 'documentElement.scrollTop', 0);

    this.setState({ scrollPosition: scrollTop });
  };

  throttleGetScrollPosition = throttle(this.getScrollPosition, 150);

  componentDidMount() {
    this.getScrollPosition();
    this.getTopBarPosition();

    window.addEventListener('scroll', this.throttleGetScrollPosition);
    this.removeResizeListener = onWindowResize(this.getTopBarPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttleGetScrollPosition);
    this.removeResizeListener();
  }

  getTopBarPosition = () => {
    if (this.topBarRef && this.topBarRef.current) {
      this.setState({
        topBarPosition: get(
          this,
          'topBarRef.current.offsetHeight',
          0
        ) as number,
      });
    }
  };

  render() {
    const hebrewDate = getHebrewDateLocal();
    const { scrollPosition, topBarPosition } = this.state;
    const {
      holidayBanner,
      featuredImage,
      featuredLink,
      showTagline,
      taglineText,
    } = this.props;

    return (
      <>
        <Nav
          isHomeNav={true}
          showMiddleLogo={true}
          isFixed={true}
          hideLogo={scrollPosition <= topBarPosition}
        />
        <div ref={this.topBarRef}>
          <TopBar className="content-padding-x inner-content-max-width mxauto">
            <div className="HomeNav flex flex-col items-center">
              {featuredImage && featuredImage.src && featuredLink ? (
                <>
                  {showTagline && taglineText && (
                    <span className="HomeNav__detail color-gray graebenbach font-400 md:mt0">
                      {taglineText}
                    </span>
                  )}
                  <Link
                    to={featuredLink ?? RouteMap.HOME.path}
                    className={cx(
                      `HomeNav__featured-logo-button mx0 trigger-LogoClick-MainLogo`,
                      {
                        'tagline-spacing': showTagline,
                      }
                    )}
                  >
                    <Img
                      className="HomeNav__featured-banner w100"
                      alt={`Tablet logo for ${
                        featuredLink ?? RouteMap.HOME.path
                      }`}
                      sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1800px"
                      src={sanityImgUtil(featuredImage, 1800)}
                      srcSet={sanityImgSrcSetUtil(
                        featuredImage,
                        768,
                        1080,
                        1800
                      )}
                    />
                  </Link>
                </>
              ) : holidayBanner && holidayBanner?.holidayBanner?.src ? (
                <>
                  {showTagline && taglineText && (
                    <span className="HomeNav__detail color-gray graebenbach font-400 md:mt0">
                      {taglineText}
                    </span>
                  )}
                  <Link
                    to={`${RouteMap.HOLIDAY.base}${holidayBanner.slug}`}
                    className={cx(
                      `HomeNav__holiday-logo-button mx0 trigger-LogoClick-MainLogo`,
                      {
                        'tagline-spacing': showTagline,
                      }
                    )}
                  >
                    <Img
                      className="HomeNav__holiday-banner w100"
                      alt={`Tablet logo for ${holidayBanner.slug}`}
                      sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1800px"
                      src={sanityImgUtil(holidayBanner.holidayBanner, 1800)}
                      srcSet={sanityImgSrcSetUtil(
                        holidayBanner.holidayBanner,
                        768,
                        1080,
                        1800
                      )}
                    />
                  </Link>
                </>
              ) : (
                <>
                  {showTagline && taglineText && (
                    <span className="HomeNav__detail color-gray graebenbach font-400 md:mt0">
                      {taglineText}
                    </span>
                  )}
                  <Link
                    to={RouteMap.HOME.path}
                    className={cx(
                      `HomeNav__logo-button mx2 sm:mx0  trigger-LogoClick-MainLogo`,
                      {
                        'tagline-spacing': showTagline,
                      }
                    )}
                  >
                    <TabletLogo className="HomeNav__logo w100" color="black" />
                  </Link>
                </>
              )}
              <div className="HomeNav__date-container graebenbach font-400 flex items-center justify-center w100">
                <span className="HomeNav__date">
                  {format(new Date(), 'dd MMMM yyyy')}
                </span>
                {!!hebrewDate && (
                  <>
                    <div className="HomeNav__dot circle mx_25" />
                    <span className="HomeNav__hebrew-date inline">{`${hebrewDate.day} ${hebrewDate.month} ${hebrewDate.year}`}</span>
                  </>
                )}
              </div>
            </div>
          </TopBar>
        </div>
      </>
    );
  }
}

export default HomeNav;
